import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
    ],
  }
  ```
*/
export default function HoutbouwPost() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Houtbouw
            </span>
            <span className="mt-2 pb-6 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              P&B Houtbouw
            </span>
          </h1>
          <figure>
            <StaticImage src="../img/thumbnail_houtbouw.png" alt="" className="w-full rounded-lg" />
          </figure>
        </div>
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <blockquote className="mt-8">
            <p>
              Ik ben recht door zee, omslachtigheid past niet bij me. Homigo sluit naadloos aan op
              de manier waarop ik werk.
            </p>
          </blockquote>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            P&B Houtbouw werkt nu ruim een jaar met Homigo en vind vooral het delen van bestanden en
            de chatfunctie een groot pluspunt. “Doordat we alles nu makkelijk onderling kunnen
            communiceren, is iedereen beter op de hoogte van de stand van zaken in de bouw. Daardoor
            gaat de foutmarge naar beneden en verbeterd de klantrelatie.
          </p>

          <h2>Verminderen van faalkosten </h2>
          <p>
            Klanten helpen ons juist in het verminderen van faalkosten, doordat ze meekijken en
            dingen zien die wij bijv. vergeten zijn of over het hoofd hebben gezien.
          </p>
          <p>
            Richard is dit bedrijf ooit gestart als hobby. Hij wilde graag de komende 25 jaar iets
            doen wat hij echt leuk vindt. Door de groei die zij doormaakten waren zij op zoek naar
            een app die hen kon ondersteunen in de uren en de communicatie naar de jongens en de
            klant.
          </p>
          <p>
            {' '}
            Met het gebruik van Homigo hebben zij, volgens eigen zeggen, een mooi &
            gebruiksvriendelijk product.
          </p>
          <a href="https://www.youtube.com/watch?v=WLQbAockzJw" target="blank">
            <button
              type="button"
              className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <span className="sr-only">Watch our video to learn more</span>

              <StaticImage src="../img/video_thumbnail_houtbouw.png" alt="" className="w-full" />

              <div
                className="absolute inset-0 w-full h-full flex items-center justify-center"
                aria-hidden="true"
              >
                <svg className="h-20 w-20 text-indigo-500" fill="currentColor" viewBox="0 0 84 84">
                  <circle opacity="0.9" cx={42} cy={42} r={42} fill="white" />
                  <path
                    fill="#FFB80D"
                    d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z"
                  />
                </svg>
              </div>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
